import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Modal, Toast} from "tw-elements";
import {PromotionalEmail} from "../../../../models/promotional-email";
import {EmailMarketingService} from "./service/email-marketing.service";

@Component({
    selector: 'app-email-marketing',
    templateUrl: './email-marketing.component.html',
    styleUrl: './email-marketing.component.css'
})
export class EmailMarketingComponent {
    isLoading: boolean = false;

    toastMessage: string = '';

    protected readonly environment = environment;

    emailControl: UntypedFormGroup = new UntypedFormGroup({
        title: new UntypedFormControl(''),
        message: new UntypedFormControl(''),
        buttonText: new UntypedFormControl(''),
        buttonLink: new UntypedFormControl(''),
    });

    constructor(
        public auth: AuthService,
        private emailMarketingService: EmailMarketingService,) {
    }

    get titleControl(): UntypedFormControl {
        return this.emailControl.get('title') as UntypedFormControl;
    }

    get messageControl(): UntypedFormControl {
        return this.emailControl.get('message') as UntypedFormControl;
    }

    get buttonTextControl(): UntypedFormControl {
        return this.emailControl.get('buttonText') as UntypedFormControl;
    }

    get buttonLinkControl(): UntypedFormControl {
        return this.emailControl.get('buttonLink') as UntypedFormControl;
    }

    openConfirmationModal(): void {
        if (this.emailControl.status === 'VALID') {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            this.openToast('Пополнете ја формата');
        }
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    sendEmail(isTest: boolean = false) {
        this.isLoading = true;

        const email: PromotionalEmail = {
            title: this.titleControl.value,
            message: this.messageControl.value,
            buttonText: this.buttonTextControl.value,
            buttonLink: this.buttonLinkControl.value
        }

        this.emailMarketingService.sendPromotionalEmail(email, isTest).subscribe({
            next: (response) => {
                alert("Вкупно потрошувачи: " + response.totalCustomers +
                    "\nВкупно пратени маилови: " + response.emailsSent);
            },
            error: () => {
                alert("Грешка при праќање на емаилот")
                this.isLoading = false;
            },
            complete: () => this.isLoading = false
        });
    }

}