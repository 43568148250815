import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.css']
})
export class ImageComponent {

    @Input()
    class: string = '';

    @Input()
    src!: string | undefined;

    @Input()
    alt: string = ''
}
