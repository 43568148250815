import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {PromotionalEmail} from "../../../../../models/promotional-email";
import {PromotionalEmailResponse} from "../../../../../models/promotional-email-response";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EmailMarketingService {

    constructor(
        private readonly httpClient: HttpClient) {
    }

    sendPromotionalEmail(email: PromotionalEmail, isTest: boolean): Observable<PromotionalEmailResponse> {
        const url: string =
            environment.domain.backend +
            environment.api.notifications +
            '/promotional-email' +
            (isTest ? '?test=true' : '');

        return this.httpClient.post<PromotionalEmailResponse>(url, email);
    }

}