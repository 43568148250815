@if (blog | async; as blog) {
    <nav class="w-full pt-24 lg:pt-40 rounded-md py-3 px-5 lg:pl-16 text-sm lg:text-base">
        <ol class="list-reset flex pt-3">
            @for (link of links; track link) {
                <li>
                    <app-link [link]="link"/>
                </li>
                <li>
                    <span class="mx-1.5 lg:mx-2 text-neutral-400">/</span>
                </li>
            }
            <li class="text-neutral-400">{{ blog.title }}</li>
        </ol>
    </nav>
    <div class="h-fit p-5 lg:w-2/3 lg:mx-auto pb-5">
        <app-text class="text-center text-2xl lg:text-5xl font-black"
                  [value]="blog.title"></app-text>
        <div class="flex justify-start py-10 lg:py-20 text-sm lg:text-base">
            <a [href]="blog.author.link">
                @if (blog.author.image) {
                    <app-image
                            [src]="blog.author.image"
                            class="w-8 lg:w-9 rounded-full shadow-lg cursor-pointer"
                            [alt]="blog.author.image"
                    />
                }
            </a>
            <p class="my-auto pl-1">
                Од <span class="text-gray-800 hover:underline cursor-pointer"> <a
                    [href]="blog.author.link">{{ blog.author.name }}</a></span> |
                {{ formatDate(datePipe, blog.publishDate!) }}
            </p>
        </div>
        <div [innerHTML]="blog.content"></div>
    </div>
    @if (products.length > 0) {
        <app-related-products [relatedProducts]="products"></app-related-products>
    }
    <app-footer></app-footer>
}