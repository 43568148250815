<div data-te-modal-init
     class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
     id="orderDialog"
     tabindex="-1"
     aria-labelledby="orderDialog"
     aria-modal="true"
     role="dialog">
    <div data-te-modal-dialog-ref
         class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[800px]">
        <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                <div></div>
                <!--Close button-->
                <button type="button"
                        class="rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none float-right"
                        data-te-modal-dismiss
                        aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         stroke-width="1.5"
                         stroke="currentColor"
                         class="h-6 w-6">
                        <path stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
            </div>

            <!--            Modal body-->
            <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
                <div id="order-dialog-carousel"
                     class="relative my-auto"
                     data-te-carousel-init>
                    <!--Carousel items-->
                    <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                        <!--First item-->
                        <div class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                             data-te-carousel-item
                             data-te-carousel-active>
                            <app-image
                                    [src]="'assets/images/' + selectedProduct?.category?.toLowerCase()?.replace('_', '-') + '/' + (selectedProduct?.images?.[0] ?? '') + '.webp'"
                                    class="block w-full"/>
                        </div>
                        @for (image of selectedProduct?.images; track image; ) {
                            @if ($index !== 0) {
                                <div class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                                     data-te-carousel-item>
                                    <app-image
                                            [src]="'assets/images/' + selectedProduct?.category?.toLowerCase()?.replace('_', '-') + '/' + (selectedProduct?.images?.[$index] ?? '') + '.webp'"
                                            class="block w-full"/>
                                </div>
                            }
                        }
                    </div>

                    <!--Carousel controls - prev item-->
                    <button
                            class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                            type="button"
                            data-te-target="#order-dialog-carousel"
                            data-te-slide="prev">
    <span class="inline-block h-8 w-8">
      <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6">
        <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"/>
      </svg>
    </span>
                        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Previous</span>
                    </button>
                    <!--Carousel controls - next item-->
                    <button class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-black hover:no-underline hover:opacity-90 hover:outline-none focus:text-black focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                            type="button"
                            data-te-target="#order-dialog-carousel"
                            data-te-slide="next">
    <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="currentColor"
           class="h-6 w-6">
        <path stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
      </svg>
    </span>
                        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Next</span>
                    </button>
                </div>
                <form [formGroup]="orderItemControl" class="pt-3 px-10 relative">
                    @if (selectedProduct && selectedProduct.category) {
                        <app-text [class]="'text-2xl font-medium text-left'"
                                  [value]="setProductCategory(selectedProduct.category) + selectedProduct.name"></app-text>
                    }
                    <hr class="h-px my-1 bg-gray-400 border-0">
                    <div class="flex text-xl text-left">
                        <app-text
                                [ngClass]="selectedProduct?.discount? 'text-red-600': ''"
                                [value]="(selectedProduct?.price! * (quantityControl.value ?? 0)) + ' ден.'"></app-text>
                        @if (selectedProduct?.discount) {
                            <app-text class="line-through px-1.5"
                                      [value]="(selectedProduct?.originalPrice! * (quantityControl.value ?? 0)) + ' ден.'"></app-text>
                        }
                    </div>
                    <div class="mt-5">
                        <div class="pb-5 flex justify-start">
                            <app-quantity-selector
                                    [control]="quantityControl"
                                    [placeholder]="'Quantity'"></app-quantity-selector>
                        </div>
                        @if (selectedProduct?.category !== 'FLAG' && selectedProduct?.category !== 'BOTTLE' && selectedProduct?.category !== 'OTHER') {
                            @if (selectedProduct?.category === 'JERSEY') {
                                <div>
                                    <app-dropdown-menu
                                            [control]="sizeControl"
                                            [placeholder]="'Величина'"
                                            [options]="jerseySizeOptions"
                                            (optionSelected)="onSizeChange($event)"></app-dropdown-menu>
                                </div>
                            } @else {
                                <div>
                                    <app-dropdown-menu
                                            [control]="sizeControl"
                                            [placeholder]="'Величина'"
                                            [options]="sizeOptions"
                                            (optionSelected)="onSizeChange($event)"></app-dropdown-menu>
                                </div>
                                <div class="pt-5">
                                    <app-dropdown-menu
                                            [control]="colorControl"
                                            [placeholder]="'Боја'"
                                            [options]="colorOptions"
                                            (optionSelected)="onColorChange($event)"></app-dropdown-menu>
                                </div>
                            }
                        } @else {
                            <div class="lg:py-10"></div>
                        }
                        @if (this.selectedProduct?.disclaimer) {
                            <app-text [class]="'text-sm pt-3 text-left text-red-500'"
                                      [value]="selectedProduct?.disclaimer"></app-text>
                        }
                        <app-text [class]="'text-sm pt-3 text-left'"
                                  [value]="selectedProduct?.description"></app-text>
                        <div class="pt-7">
                            @if (orderItemControl.status === "VALID" || this.selectedProduct?.category === "FLAG" || this.selectedProduct?.category === "BOTTLE" || selectedProduct?.category === 'OTHER' || selectedProduct?.category === 'JERSEY') {
                                <app-secondary-button
                                        class="flex justify-center items-center"
                                        value="Додади во кошничка"
                                        (click)="addToCart()"
                                        data-te-modal-dismiss></app-secondary-button>
                                <app-primary-button
                                        class="pt-3 pb-5 flex justify-center items-center"
                                        value="Купи сега"
                                        (click)="buyNow()"
                                        data-te-modal-dismiss></app-primary-button>
                            } @else {
                                <app-secondary-button
                                        class="flex justify-center items-center"
                                        value="Додади во кошничка"
                                        (click)="addToCart()"></app-secondary-button>
                                <app-primary-button
                                        class="pt-3 pb-5 flex justify-center items-center"
                                        value="Купи сега"
                                        (click)="buyNow()"></app-primary-button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-toast [message]="toastMessage"></app-toast>
</div>