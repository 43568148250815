import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmailMarketingComponent} from "./email-marketing.component";
import {DropdownMenuModule} from "../../../../components/dropdown/dropdown-menu.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ImageModule} from "../../../../components/image/image.module";
import {PrimaryButtonComponent} from "../../../../components/button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../../../../components/button/secondary/secondary.button.component";
import {TextInputModule} from "../../../../components/text-input/text-input.module";
import {TextModule} from "../../../../components/text/text.module";
import {ConfirmationModalModule} from "../../../../components/confirmation-modal/confirmation-modal.module";
import {EmailMarketingService} from "./service/email-marketing.service";


@NgModule({
    declarations: [EmailMarketingComponent],
    imports: [
        CommonModule,
        DropdownMenuModule,
        FormsModule,
        ImageModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        TextInputModule,
        TextModule,
        ReactiveFormsModule,
        ConfirmationModalModule
    ],
    exports: [
        EmailMarketingComponent
    ],
    providers: [EmailMarketingService]
})
export class EmailMarketingModule {
}