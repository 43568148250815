import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Discount} from "../../../models/discount";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DiscountManageService {

    constructor(
        private readonly httpClient: HttpClient) {
    }

    getDiscountById(id: number): Observable<Discount> {
        const url: string =
            environment.domain.backend +
            environment.api.products + environment.api.discounts +
            "/" + id;

        return this.httpClient.get<Discount>(url);
    }

    addDiscount(discount: Discount): Observable<Discount> {
        const url: string = environment.domain.backend + environment.api.products + environment.api.discounts;

        return this.httpClient.post<Discount>(url, discount);
    }

    deleteDiscount(discountId: number): Observable<void> {
        const url: string = environment.domain.backend + environment.api.products + environment.api.discounts + "/" + discountId;

        return this.httpClient.delete<void>(url);
    }

}