import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrandAmbassadorComponent} from "./brand-ambassador.component";
import {AppRoutingModule} from "../../app-routing.module";
import {FooterModule} from "../../components/footer/footer.module";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {ToastBarModule} from "../../components/toast-bar/toast-bar.module";


@NgModule({
    declarations: [BrandAmbassadorComponent],
    exports: [BrandAmbassadorComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        FooterModule,
        PrimaryButtonComponent,
        ToastBarModule
    ]
})
export class BrandAmbassadorModule {
}
