import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Customer} from "../../../models/customer";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    updateCustomer(customer: Customer): Observable<Customer> {
        if (customer.email === undefined) {
            return of(customer);
        }

        const url: string = environment.domain.backend + environment.api.customers;

        return this.httpClient.post<Customer>(url, customer);
    }

    updateCouponCode(customerId: number, couponCode: string): Observable<string> {
        const url: string = environment.domain.backend + environment.api.customers + '/update-coupon-code';

        const params = new HttpParams()
            .set('customerId', customerId.toString())
            .set('couponCode', couponCode);

        return this.httpClient.post(url, {}, { params, responseType: 'text' });
    }

    getCustomerByEmail(email: string | undefined): Observable<Customer> {
        const url: string = environment.domain.backend + environment.api.customers + "/email/" + email;

        return this.httpClient.get<Customer>(url);
    }
}