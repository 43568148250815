import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutUsComponent} from "./modules/about-us/about-us.component";
import {ShopComponent} from "./modules/shop/shop.component";
import {CartComponent} from "./modules/cart/cart.component";
import {CheckoutComponent} from "./modules/checkout/checkout.component";
import {HomeComponent} from "./modules/home/home.component";
import {ProfileComponent} from "./modules/profile/profile.component";
import {ContactUsComponent} from "./modules/contact-us/contact-us.component";
import {AdminPanelComponent} from "./modules/admin-panel/admin-panel.component";
import {ProductPageComponent} from "./modules/shop/components/product-page/product-page.component";
import {StatusPageComponent} from "./components/status-page/status-page.component";
import {ProductManageComponent} from "./modules/shop/components/product-manage/product-manage.component";
import {DiscountManageComponent} from "./components/discount-manage/discount-manage.component";
import {EmailMarketingComponent} from "./modules/admin-panel/components/email-marketing/email-marketing.component";
import {BlogDetailComponent} from "./modules/blog/components/blog-detail/blog-detail.component";
import {BlogManageComponent} from "./modules/blog/components/blog-manage/blog-manage.component";
import {BlogListComponent} from "./modules/blog/components/blog-list/blog-list.component";
import {BrandAmbassadorComponent} from "./modules/brand-ambassador/brand-ambassador.component";

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'Непокор.мк - вечна борба, непокорен дух!',
            metaDescription: 'Најголем избор на македонски патриотски производи на едно место. Од македонски знамиња, македонски дресови, маици, дуксери, блузони до стикери, шишиња и други производи за патриоти. Посетете нè сега!',
            canonicalUrl: '/'
        }
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
            title: 'За Нас',
            metaDescription: 'Дознајте повеќе за Непокор.мк и нашата мисија да промовираме македонски патриотизам преку нашата колекција на производи и нашата посветеност кон македонската историја.',
            canonicalUrl: '/about-us'
        }
    },
    {
        path: 'shop',
        component: ShopComponent,
        data: {
            title: 'Продавница',
            metaDescription: 'Истражете ја нашата продавница со широк избор на македонски патриотски производи, вклучувајќи, македонски знамиња, патриотски маици, дресови и многу повеќе.',
            canonicalUrl: '/shop'
        }
    },
    {
        path: 'shop/t_shirts',
        component: ShopComponent,
        data: {
            title: 'Маици',
            metaDescription: 'На Непокор.мк ќе најдете патриотски маици со инспиративни дизајни и квалитетни материјали, совршени за секојдневно носење или како подарок.',
            canonicalUrl: '/shop/t_shirts'
        }
    },
    {
        path: 'shop/flags',
        component: ShopComponent,
        data: {
            title: 'Македонски знамиња',
            metaDescription: 'Изберете од нашата колекција на македонски знамиња, изработени од висококвалитетни материјали во различни големини. Покажете ја вашата гордост и љубов кон Македонија со знамиња совршени за дом, канцеларија или јавни настани.',
            canonicalUrl: '/shop/flags'
        }
    },
    {
        path: 'shop/hoodies',
        component: ShopComponent,
        data: {
            title: 'Дуксери',
            metaDescription: 'Откријте ја нашата колекција на дуксери на Непокор.мк, изработени од висококвалитетни материјали. Совршени за секојдневно носење или како подарок, нашите дуксери со патриотски мотиви ќе ви обезбедат стил и комфор.',
            canonicalUrl: '/shop/hoodies'
        }
    },
    {
        path: 'shop/sweat_shirts',
        component: ShopComponent,
        data: {
            title: 'Блузони',
            metaDescription: 'Откријте ја нашата колекција на блузони на Непокор.мк, изработени од висококвалитетни материјали и со модерен дизајн. Совршени за секојдневно носење или како подарок, нашите блузони со патриотски мотиви ќе ви обезбедат стил и комфор.',
            canonicalUrl: '/shop/sweat_shirts'
        }
    },
    {
        path: 'shop/jerseys',
        component: ShopComponent,
        data: {
            title: 'Дресови',
            metaDescription: 'Нашите дресови од се совршени за спортски активности или како подарок за вистинските навивачи на Македонија. Носете ги боите на националниот тим и поддржете ја репрезентацијата со стил и гордост.',
            canonicalUrl: '/shop/jerseys'
        }
    },
    {
        path: 'shop/bottles',
        component: ShopComponent,
        data: {
            title: 'Шишиња',
            metaDescription: 'Изберете од нашите уникатни шишиња и чоканчиња со ликови на македонски револуционери. Нашата колекција вклучува рачно изработени парчиња, совршени за секојдневна употреба или како патриотски подарок.',
            canonicalUrl: '/shop/bottles'
        }
    },
    {
        path: 'shop/other',
        component: ShopComponent,
        data: {
            title: 'Останато',
            metaDescription: 'Изберете од патриотски производи на Непокор.мк, вклучувајќи кокарди, шалови, стикери и знамиња за на ретровизори. Носете македонски симболи со гордост каде и да одите.',
            canonicalUrl: '/shop/other'
        }
    },
    {
        path: 'shop/on-discount',
        component: ShopComponent,
        data: {
            title: 'Попусти',
            metaDescription: 'Искористете ги ексклузивните попусти на Непокор.мк! Најдете патриотски производи по намалени цени, вклучувајќи маици и знамиња и др. Искористете ја шансата за одлични понуди и заштедете.',
            canonicalUrl: '/shop/on-discount'
        }
    },
    {
        path: 'shop/product/discount/add',
        component: DiscountManageComponent
    },
    {
        path: 'shop/product/discount/:id/edit',
        component: DiscountManageComponent
    },
    {
        path: 'shop/product/add',
        component: ProductManageComponent
    },
    {
        path: 'shop/product/:slugAndId',
        component: ProductPageComponent
    },
    {
        path: 'shop/product/:id/edit',
        component: ProductManageComponent
    },
    {
        path: 'blogs',
        component: BlogListComponent,
        data: {
            title: 'Блог',
            metaDescription: 'Прочитајте ги најновите објави на Непокор.мк.',
            canonicalUrl: '/blogs'
        }
    },
    {
        path: 'blogs/add',
        component: BlogManageComponent
    },
    {
        path: 'blogs/:slugAndId',
        component: BlogDetailComponent
    },
    {
        path: 'blogs/:id/edit',
        component: BlogManageComponent
    },
    {
        path: 'cart',
        component: CartComponent,
        data: {
            title: 'Koшничка',
            metaDescription: 'Вашата кошничка на Непокор.мк. Прегледајте ги избраните производи и продолжете со наплатата за завршување на купувањето.',
            canonicalUrl: '/cart'
        }
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        data: {
            title: 'Наплата',
            metaDescription: 'Внесете ги вашите податоци за испорака на Непокор.мк. Завршете ја вашата нарачка со пополнување на информациите потребни за испорака.',
            canonicalUrl: '/checkout'
        }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            title: 'Профил',
            metaDescription: 'Вашиот кориснички профил на Непокор.мк.',
            canonicalUrl: '/profile'
        }
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
            title: 'Контактирајте нè',
            metaDescription: 'Контактирајте го тимот на Непокор.мк за помош или информации. Пополнете го контакт формуларот или користете ги нашите информации за контакт за испратите порака.',
            canonicalUrl: '/contact-us'
        }
    },
    {
        path: 'brand-ambassador',
        component: BrandAmbassadorComponent,
        data: {
            title: 'Стани бренд амбасадор!',
            metaDescription: 'Стани дел од амбасадорите на Непокор и почни да заработуваш уште денес.',
            canonicalUrl: '/brand-ambassador'
        }
    },
    {
        path: 'admin-panel',
        component: AdminPanelComponent,
        data: {
            title: 'Админ Панел',
            metaDescription: 'Админ панелот за управување со вашата продавница.',
            canonicalUrl: '/admin-panel'
        }
    },
    {
        path: 'admin-panel/email-marketing',
        component: EmailMarketingComponent
    },
    {
        path: '404',
        component: StatusPageComponent,
        data: {
            title: '404',
            message: 'Извинете, страницата што ја барате не постои.',
            style: 'text-red-600',
            buttonLabel: 'Назад кон почетната страница',
            buttonUrl: '/',
            canonicalUrl: '/404'
        }
    },
    {
        path: '403',
        component: StatusPageComponent,
        data: {
            title: '403',
            message: 'Немате овластување да пристапите до оваа страница.',
            style: 'text-red-600',
            buttonLabel: 'Контактирајте нè',
            buttonUrl: '/contact-us',
            canonicalUrl: '/403'
        }
    },
    {
        path: 'uspesna-naracka',
        component: StatusPageComponent,
        data: {
            title: 'Успешна нарачка!',
            message: 'Ти благодариме за нарачката!',
            style: 'text-green-600',
            buttonLabel: 'Назад кон почетната страница',
            buttonUrl: '/',
            canonicalUrl: '/order-success'
        }
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
