<div class="p-0 lg:p-16 pt-24 lg:pt-40">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
                <div class="pt-3 lg:pt-8 px-5 relative">
                    <div>
                        <app-text [value]="'Наслов'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси наслов'"
                                [control]="titleControl"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Категорија'"></app-text>
                        <app-text-input [placeholder]="'Внеси категорија'"
                                        [control]="categoryControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Краток опис'"></app-text>
                        <app-text-input [placeholder]="'Внеси краток опис'"
                                        [control]="summaryControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Насловна слика'"></app-text>
                        <app-text-input [placeholder]="'Внеси насловна слика'"
                                        [control]="mainImageControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Слаг'"></app-text>
                        <app-text-input [placeholder]="'Внеси слаг'"
                                        [control]="slugControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Клучни зборови'"></app-text>
                        <app-text-input [placeholder]="'Внеси клучни зборови'"
                                        [control]="keywordsControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Име на авторот'"></app-text>
                        <app-text-input [placeholder]="'Внеси име на авторот'"
                                        [control]="authorNameControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Слика на авторот'"></app-text>
                        <app-text-input [placeholder]="'Внеси слика на авторот'"
                                        [control]="authorImageControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Линк до авторот'"></app-text>
                        <app-text-input [placeholder]="'Внеси линк до авторот'"
                                        [control]="authorLinkControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Продукти за реклама'"></app-text>
                        <app-text-input [placeholder]="'Внеси ид на продукти за реклама'"
                                        [control]="productAdIdsControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                </div>

                <div class="lg:pt-8 + px-5">
                    <app-text [value]="'Содржина'"></app-text>
                    <app-rich-text [editorContent]="editorContent"
                                   [control]="contentControl"
                                   (editorDataChange)="onEditorDataChange($event)"></app-rich-text>
                </div>
            </div>
            <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
                @if (isEditMode) {
                    <app-secondary-button
                            class="bg-red-600"
                            [value]="'Избриши го блогот'"
                            (click)="openConfirmationModal()"/>
                    <app-confirmation-modal (confirmationEvent)="deleteBlog()"></app-confirmation-modal>

                    <app-primary-button [value]="'Промени ги податоците'"
                                        (click)="updateBlog()"></app-primary-button>
                } @else {
                    <app-primary-button [value]="'Додади нов блог'"
                                        (click)="addBlog()"></app-primary-button>
                }
            </div>
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>