import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Order, OrderCreationRequest} from "../../../models/order";
import {CouponCodeRequest} from "../../../models/coupon-code-request";
import {CouponCodeResponse} from "../../../models/coupon-code-response";

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {

    constructor(private readonly httpClient: HttpClient) {
    }

    submitOrder(orderCreationRequest: OrderCreationRequest): Observable<Order> {
        const url: string = environment.domain.backend + environment.api.orders;
        return this.httpClient.post<Order>(url, orderCreationRequest);
    }

    applyCoupon(couponCodeRequest: CouponCodeRequest): Observable<CouponCodeResponse> {
        const url: string = environment.domain.backend + environment.api.orders + "/apply-coupon"
        return this.httpClient.post<CouponCodeResponse>(url, couponCodeRequest);
    }
}
