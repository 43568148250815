import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "../../environments/environment";
import {getPageInfoDefaults, Page, PageInfo} from "../../models/paginator";
import {AdminPanelService} from "./service/admin-panel.service";
import {Customer} from "../../models/customer";
import {Order} from "../../models/order";
import {Subscription} from "rxjs";
import {ActivateEvent, TableColumn} from '../../components/table/table.component'
import {goToUrl} from "../../services/util/util";
import {Link} from "../../models/link";
import {Router} from "@angular/router";
import {Product} from "../../models/product";
import {ShopService} from "../shop/service/shop.service";
import {Discount} from "../../models/discount";

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrl: './admin-panel.component.css'
})
export class AdminPanelComponent implements OnInit, OnDestroy {

    protected readonly environment = environment;

    activeTable: string = "orders";

    pageInfo: PageInfo = getPageInfoDefaults();

    customers: Customer[] = [];

    orders: Order[] = [];

    products: Product[] = [];

    discounts: Discount[] = [];

    pageNumber: number = 0;

    pageSize: number = 20;

    totalPages: number = 0;

    private ordersPageSub!: Subscription;

    private productsPageSub!: Subscription;

    orderColumns: TableColumn[] = [
        {prop: 'productName', name: 'Производ', width: 310},
        {prop: 'orderColor', name: 'Боја', width: 60},
        {prop: 'orderSize', name: 'Величина', width: 60},
        {prop: 'orderQuantity', name: 'Количина', width: 60},
        {prop: 'orderCreated', name: 'Датум', width: 200},
        {prop: 'customerFullName', name: 'Име и презиме', width: 200},
        {prop: 'customerAddress', name: 'Адреса', width: 350},
        {prop: 'customer.phoneNumber', name: 'Број', width: 100},
        {prop: 'productPrice', name: 'Цена', width: 100},
        {prop: 'totalPrice', name: 'Вкупна Цена', width: 100},
        {prop: 'usePoints', name: 'Користени поени за наплата', width: 100},
        {prop: 'usedCoupon', name: 'Искористен купон', width: 100}
    ];

    productColumns: TableColumn[] = [
        {prop: 'name', name: 'Име', width: 200},
        {prop: 'category', name: 'Категорија', width: 80},
        {prop: 'designID', name: 'Дизајн', width: 80},
        {prop: 'description', name: 'Опис', width: 200},
        {prop: 'subCategory', name: 'Подкатегорија', width: 20},
        {prop: 'images', name: 'слики', width: 80},
        {prop: 'availableSizes', name: 'Величини', width: 100},
        {prop: 'availableColors', name: 'Бои', width: 80},
        {prop: 'originalPrice', name: 'Оргинална Цена', width: 60},
        {prop: 'price', name: 'Цена со попуст', width: 60},
        {prop: 'created', name: 'Креиран', width: 100},
        {prop: 'discount', name: 'Попуст', width: 100},
    ];

    customerColumns: TableColumn[] = [
        {prop: 'name', name: 'Име', width: 100},
        {prop: 'surname', name: 'Презиме', width: 100},
        {prop: 'address', name: 'Адреса', width: 100},
        {prop: 'city', name: 'Град', width: 80},
        {prop: 'phoneNumber', name: 'Број', width: 100},
        {prop: 'email', name: 'Е-маил', width: 120},
        {prop: 'couponCode', name: 'Купон код', width: 120},
        {prop: 'totalPoints', name: 'Вкупно поени', width: 120},
        {prop: 'currentPoints', name: 'Неискористени поени', width: 120}
    ];

    discountColumns: TableColumn[] = [
        {prop: 'name', name: 'Име', width: 100},
        {prop: 'discountPercentage', name: 'Процент', width: 100},
        {prop: 'isActive', name: 'Активен', width: 100},
        {prop: 'startDate', name: 'Почетен датум', width: 80},
        {prop: 'endDate', name: 'Краен датум', width: 100},
        {prop: 'discountCode', name: 'Код', width: 120}
    ];

    links: Link[] = [
        {text: "Нарачки", route: "orders"},
        {text: "Потрошувачи", route: "customers"},
        {text: "Продукти", route: "products"},
        {text: "Попусти", route: "discounts"},
        {text: "Бренд амбасадори", route: "ambassadors"}
    ];

    constructor(
        public auth: AuthService,
        private readonly adminPanelService: AdminPanelService,
        protected readonly router: Router,
        private readonly shopService: ShopService) {
    }

    ngOnInit(): void {
        this.getPaginatedOrders();
    }

    ngOnDestroy(): void {
        if (this.ordersPageSub) {
            this.ordersPageSub.unsubscribe();
        }
    }

    private loadData(): void {
        this.getPaginatedOrders();
        this.getPaginatedProducts();
        this.getPaginatedCustomers();
    }

    setActiveTable(table: string) {
        this.activeTable = table;
        switch (table) {
            case "orders":
                this.getPaginatedOrders();
                break;
            case "customers":
                this.getPaginatedCustomers();
                break;
            case "products":
                this.getPaginatedProducts();
                break;
            case "discounts":
                this.getDiscountList();
                break;
            case "ambassadors":
                this.getCustomersWithCouponCode();
                break;
        }
    }

    onPageChange(page: number): void {
        this.pageNumber = page;
        this.loadData();
    }

    getPaginatedOrders(): void {
        this.adminPanelService.getPaginatedOrders(this.pageNumber, this.pageSize);
        this.ordersPageSub = this.adminPanelService.ordersPage$.subscribe((page) => {
            this.totalPages = page.totalPages;
            this.orders = this.processOrders(page.content);
            this.pageInfo.totalElements = page.totalElements;
        });
    }

    getPaginatedProducts(): void {
        this.shopService.getAllProducts(this.pageNumber, this.pageSize);
        this.productsPageSub = this.shopService.productsPage$.subscribe((page) => {
            this.totalPages = page.totalPages;
            this.products = page.content;
            this.pageInfo.totalElements = page.totalElements;
        });
    }

    getPaginatedCustomers(): void {
        this.adminPanelService.getAllCustomers(this.pageNumber, this.pageSize);
        this.adminPanelService.customersPage$
            .pipe()
            .subscribe((customersPage: Page<Customer>) => {
                this.customers = customersPage.content;
                this.pageInfo.totalElements = customersPage.totalElements;
            });
    }

    getCustomersWithCouponCode() {
        this.adminPanelService.getAllCustomersWithCouponCode().subscribe((customers) => {
            this.customers = customers;
            this.pageInfo.totalElements = customers.length;
        });
    }

    getDiscountList() {
        this.shopService.getAllDiscounts().subscribe(discounts => {
            this.discounts = discounts
            this.pageInfo.totalElements = discounts.length;
        });
    }

    private processOrders(orders: Order[]): Array<Order & {
        productName: string;
        orderColor: string;
        orderSize: string;
        orderQuantity: number;
        productPrice: number;
        customerFullName: string;
        customerAddress: string;
    }> {
        return orders.flatMap(order =>
            order.orderItems.map(orderItem => ({
                ...order,
                productName: this.formatProductCategory(orderItem.product!.category) + orderItem?.product?.name + ' ' + orderItem.product?.designID || '',
                orderColor: orderItem?.color || '',
                orderSize: orderItem?.size || '',
                orderQuantity: orderItem?.quantity || 0,
                productPrice: orderItem?.product?.price || 0,
                customerFullName: `${order.customer.name || ''} ${order.customer.surname || ''}`,
                customerAddress: `${order.customer.address || ''} ${order.customer.city || ''}`,
            }))
        );
    }

    private formatProductCategory(category?: string): string {
        const categories: { [key: string]: string } = {
            'T_SHIRT': 'Маица - ',
            'SWEAT_SHIRT': 'Блузон - ',
            'HOODIE': 'Дуксер - ',
            'JERSEY': 'Дрес - ',
            'FLAG': 'Знаме - '
        };
        return category ? categories[category] || '' : '';
    }

    onDiscountRowClick(event: ActivateEvent) {
        goToUrl(this.router, 'shop/product/discount/' + event.row?.id + '/edit')
    }

    onProductRowClick(event: ActivateEvent) {
        goToUrl(this.router, 'shop/product/' + event.row?.id + '/edit')
    }

    protected readonly goToUrl = goToUrl;
}