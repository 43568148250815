<div class="relative overflow-hidden px-5 space-y-6  pt-28 lg:pt-44 md:w-1/2 h-fit mx-auto">
    <div class="py-4">
        <div class="space-y-3 pb-10">
            <div class="max-w-lg mx-auto p-6 border border-gray-300 rounded-lg bg-gray-50">
                <h2 class="text-center text-2xl font-bold text-gray-800">Стани Непокор бренд амбасадор!</h2>
                <p class="text-gray-600 mt-2">Сподели го твојот личен код и добиј поени секогаш кога твој пријател
                    ќе
                    направи нарачка!</p>
                <h3 class="text-lg font-semibold text-gray-800 mt-4">Како функционира:</h3>
                <ol class="list-decimal list-inside text-gray-600 mt-2">
                    <li>Логирај се преку Google или Facebook.</li>
                    <app-primary-button
                            class="w-8 lg:w-9 button-on-hover z-[100]"
                            [value]="'Логирај се'"
                            [tooltip]="'Profile'"
                            (click)="auth.loginWithRedirect()"/>
                    <li>Внеси твој уникатен купон код.</li>
                    <li>Сподели го кодот – твоите пријатели добиваат попуст од 10% при нарачка.</li>
                    <li>Заработи поени – добиваш 10% од вредноста на нарачките во поени.</li>
                </ol>

                <h3 class="text-lg font-semibold text-gray-800 mt-4">Придобивки:</h3>
                <ul class="list-disc list-inside text-gray-600 mt-2">
                    <li>Користи ги поените за да си купиш производи од сајтот (1 поен = 1 денар).</li>
                    <li>Собери 500, 1000 или 1500 поени за бесплатни производи!</li>
                </ul>
                <p class="text-center text-xl font-bold text-gray-800 mt-6 ">Почни да заработуваш денес!</p>
                <h3 class="text-lg font-semibold text-gray-800 mt-4">Ти треба помош при најава?</h3>
                <ul class="list-disc list-inside text-gray-600 mt-2">
                    <li>Пиши ни на инстаграм <a class="text-gray-900 hover:text-[#3b71ca] underline"
                                                href="'https://www.instagram.com/nepokor.mk/'">непокор.мк</a></li>
                    <li>или на маил <a class="text-gray-900 hover:text-[#3b71ca] hover:underline"
                                       href="mailto:nepokormacedonia@gmail.com">nepokormacedonia&#64;gmail.com</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<app-toast [message]="toastMessage"></app-toast>
<app-footer></app-footer>