export const environment = {
    production: true,
    domain: {
        frontend: 'https://nepokor.mk',
        backend: `https://nepokor.mk:8443/api/v1`,
    },
    navigation: {
        home: '/',
        aboutUs: '/about-us',
        shop: '/shop',
        blog: '/blogs',
        contact: '/contact',
        shoppingCart: '/cart',
        profile: '/profile',
        contactUs: '/contact-us',
        brandAmbassador: '/brand-ambassador'
    },
    api: {
        products: '/products',
        discounts: '/discounts',
        customers: '/customers',
        orders: '/orders',
        notifications: '/notifications',
        spotify: '/spotify',
        blogs: '/blogs'
    },
    auth0: {
        domain: 'dev-us8tff5ew2sno1b4.us.auth0.com',
        clientId: 'mxl7Uf8h6kFVefRn3w4ipBVseoIEtThU'
    },
    admin: "kiko.milenkovski10@gmail.com"
}
