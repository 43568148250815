import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "@auth0/auth0-angular";
import {Collapse, initTE, Toast} from "tw-elements";
import {ProfileService} from "./service/profile.service";
import {Customer} from "../../models/customer";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {goToUrl} from "../../services/util/util";
import {Product} from "../../models/product";
import {map, switchMap} from "rxjs";
import {ShopService} from "../shop/service/shop.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.css',
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})

export class ProfileComponent implements OnInit {

    customer?: Customer;

    protected readonly document = document;

    toastMessage: string = '';

    isLoading: boolean = true;

    customerControl: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        surname: new UntypedFormControl(''),
        email: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', [Validators.pattern('^\\s*\\d+(\\s+\\d+)*\\s*$')]),
        address: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        couponCode: new UntypedFormControl('', Validators.required),
    });

    referralProductPrices: Product[] = []

    checkpoints: number[] = [
        500, 1000, 1500, 2000, 2500
    ]

    constructor(public auth: AuthService,
                private readonly profileService: ProfileService,
                protected readonly router: Router,
                protected readonly shopService: ShopService,
    ) {
    }

    ngOnInit(): void {
        initTE({Toast, Collapse});
        this.setCustomer();
        this.getReferralProductPrices();
    }

    get nameControl(): UntypedFormControl {
        return this.customerControl.get('name') as UntypedFormControl;
    }

    get surnameControl(): UntypedFormControl {
        return this.customerControl.get('surname') as UntypedFormControl;
    }

    get emailControl(): UntypedFormControl {
        return this.customerControl.get('email') as UntypedFormControl;
    }

    get phoneNumberControl(): UntypedFormControl {
        return this.customerControl.get('phoneNumber') as UntypedFormControl;
    }

    get addressControl(): UntypedFormControl {
        return this.customerControl.get('address') as UntypedFormControl;
    }

    get cityControl(): UntypedFormControl {
        return this.customerControl.get('city') as UntypedFormControl;
    }

    get couponCodeControl(): UntypedFormControl {
        return this.customerControl.get('couponCode') as UntypedFormControl;
    }

    getReferralProductPrices(): void {
        this.shopService.getProductsByIds([704, 852, 105, 107, 608]).subscribe((products => this.referralProductPrices = products));
    }

    setCustomerValues(customer: Customer) {
        this.nameControl.setValue(customer.name);
        this.surnameControl.setValue(customer.surname);
        this.addressControl.setValue(customer.address);
        this.emailControl.setValue(customer.email);
        this.cityControl.setValue(customer.city);
        this.phoneNumberControl.setValue(customer.phoneNumber);
        this.couponCodeControl.setValue(customer.couponCode);
    }

    setCustomer() {
        return this.auth.user$.pipe(
            switchMap(user =>
                this.profileService.getCustomerByEmail(user?.email).pipe(
                    map(customer => customer || {
                        name: user?.given_name,
                        surname: user?.family_name,
                        email: user?.email
                    })
                )
            )
        ).subscribe(customer => {
            this.customer = customer;
            this.setCustomerValues(customer);
        });
    }

    updateCustomer() {
        if (this.customerControl.status === "VALID") {
            const customer: Customer = {
                name: this.nameControl.value ? this.nameControl.value : this.customer?.name,
                surname: this.surnameControl.value ? this.surnameControl.value : this.customer?.name,
                email: this.customer?.email,
                address: this.addressControl.value,
                city: this.cityControl.value,
                phoneNumber: this.phoneNumberControl.value,
                couponCode: this.couponCodeControl.value ? this.couponCodeControl.value : null
            };

            this.profileService.updateCustomer(customer).subscribe({
                next: () => {
                    this.openToast("Успешно ажурирање на податоци");
                },
                error: () => this.openToast(`Грешка при ажурирање на податоци.`),
            });
        } else {
            this.openToast("Невалиден телефонски број");
        }
    }

    updateCouponCode() {
        if (this.customer?.id && this.couponCodeControl.status === "VALID") {
            this.profileService.updateCouponCode(this.customer.id, this.couponCodeControl.value).subscribe({
                next: () => {
                    this.customer!.couponCode = this.couponCodeControl.value;
                    this.openToast("Успешно креиран код!");
                },
                error: () => {
                    this.openToast(`Купон кодот е веќе зафатен од друг корисник. Обиди се со нов.`);
                },
            });
        } else this.openToast(`Внеси купон код.`);
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    protected readonly environment = environment;
    protected readonly goToUrl = goToUrl;
}