import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiscountManageComponent} from "./discount-manage.component";
import {ConfirmationModalModule} from "../confirmation-modal/confirmation-modal.module";
import {DropdownMenuModule} from "../dropdown/dropdown-menu.module";
import {ImageModule} from "../image/image.module";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SecondaryButtonComponent} from "../button/secondary/secondary.button.component";
import {TextInputModule} from "../text-input/text-input.module";
import {TextModule} from "../text/text.module";
import {DiscountManageService} from "./service/discount-manage.service";
import {CheckboxModule} from "../checkbox/checkbox.module";

@NgModule({
    declarations: [DiscountManageComponent],
    exports: [
        DiscountManageComponent
    ],
    imports: [
        CommonModule,
        ConfirmationModalModule,
        DropdownMenuModule,
        ImageModule,
        PrimaryButtonComponent,
        ReactiveFormsModule,
        SecondaryButtonComponent,
        TextInputModule,
        TextModule,
        CheckboxModule
    ],
    providers: [DiscountManageService]
})
export class DiscountManageModule {
}