import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckboxComponent} from "./checkbox.component";


@NgModule({
    declarations: [CheckboxComponent],
    exports: [
        CheckboxComponent
    ],
    imports: [
        CommonModule
    ]
})
export class CheckboxModule {
}
