import {Component, OnInit} from '@angular/core';
import {Blog} from "../../../../models/blog";
import {ActivatedRoute, Router} from "@angular/router";
import {BlogService} from "../service/blog.service";
import {DatePipe} from "@angular/common";
import {formatDate, goToUrl} from "../../../../services/util/util";
import {Meta, Title} from "@angular/platform-browser";
import {animate, style, transition, trigger} from "@angular/animations";
import {environment} from "../../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";

@Component({
    selector: 'app-blog-list',
    templateUrl: './blog-list.component.html',
    styleUrl: './blog-list.component.css',
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})
export class BlogListComponent implements OnInit {

    pageTitle: string = ''

    blogs: Blog[] = [];

    constructor(private route: ActivatedRoute,
                protected readonly router: Router,
                private blogService: BlogService,
                protected datePipe: DatePipe,
                private titleService: Title,
                private metaService: Meta,
                public auth: AuthService
    ) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.pageTitle = data['title'] || 'Блог';
            this.titleService.setTitle(this.pageTitle);

            const canonicalUrl = data['canonicalUrl'];
            if (canonicalUrl) {
                this.metaService.updateTag({rel: 'canonical', href: canonicalUrl});
            }
        });

        this.blogService.getAllBlogs().subscribe((blogs) => this.blogs = blogs);
    }

    protected readonly goToUrl = goToUrl;
    protected readonly environment = environment;
    protected readonly formatDate = formatDate;
}