import {Component, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Discount} from "../../models/discount";
import {DiscountManageService} from "./service/discount-manage.service";
import {environment} from "../../environments/environment";
import {Modal, Toast} from "tw-elements";
import {of, switchMap} from "rxjs";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-discount-manage',
    templateUrl: './discount-manage.component.html',
    styleUrl: './discount-manage.component.css'
})
export class DiscountManageComponent implements OnInit {

    discountId?: number;

    isEditMode: boolean = false;

    toastMessage: string = '';

    discountControl: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        discountPercentage: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
        isActive: new UntypedFormControl(''),
        startDate: new UntypedFormControl(''),
        endDate: new UntypedFormControl(''),
        discountCode: new UntypedFormControl('')
    });

    constructor(public auth: AuthService,
                private route: ActivatedRoute,
                private discountManageService: DiscountManageService,
                protected readonly router: Router) {
    }

    ngOnInit(): void {
        this.route.paramMap.pipe(
            switchMap(params => {
                const id = params.get('id');
                if (id) {
                    this.isEditMode = true;
                    return this.discountManageService.getDiscountById(+id);
                } else {
                    this.isEditMode = false;
                    return of(null);
                }
            })
        ).subscribe(discount => {
            if (this.isEditMode && discount) {
                this.discountId = discount.id!;
                this.setDiscountValues(discount);
            }
        });
    }

    get nameControl(): UntypedFormControl {
        return this.discountControl.get('name') as UntypedFormControl;
    }

    get discountPercentageControl(): UntypedFormControl {
        return this.discountControl.get('discountPercentage') as UntypedFormControl;
    }

    get isActiveControl(): UntypedFormControl {
        return this.discountControl.get('isActive') as UntypedFormControl;
    }

    get startDateControl(): UntypedFormControl {
        return this.discountControl.get('startDate') as UntypedFormControl;
    }

    get endDateControl(): UntypedFormControl {
        return this.discountControl.get('endDate') as UntypedFormControl;
    }

    get discountCodeControl(): UntypedFormControl {
        return this.discountControl.get('discountCode') as UntypedFormControl;
    }

    setDiscountValues(discount: Discount) {
        this.discountControl.patchValue({
            name: discount.name,
            discountPercentage: discount.discountPercentage,
            active: discount.active,
            startDate: discount.startDate,
            endDate: discount.endDate,
            discountCode: discount.discountCode,
        });
    }

    handleCheckboxChange(isChecked: boolean): void {
        this.isActiveControl.setValue(isChecked);
    }

    addDiscount(): void {
        const discount: Discount = {
            name: this.nameControl.value,
            discountPercentage: this.discountPercentageControl.value,
            active: this.isActiveControl.value,
            startDate: this.startDateControl.value,
            endDate: this.endDateControl.value,
            discountCode: this.discountCodeControl.value
        }

        this.discountManageService.addDiscount(discount).subscribe({
            next: () => alert("Успешно додаден попуст"),
            error: () => alert("Грешка при додавање на попуст"),
        });
    }

    deleteDiscount() {
        this.discountManageService.deleteDiscount(this.discountId!).subscribe({
            next: () => {
                alert("Успешно избришан попуст");
                goToUrl(this.router, "/admin-panel");
            },
            error: () => alert("Грешка при бришење на попустот"),
        });
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    openConfirmationModal(): void {
        if (this.discountControl.status === 'VALID') {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            this.openToast('Пополнете ја формата');
        }
    }

    protected readonly environment = environment;
}

