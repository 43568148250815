<div>
    <div class="main-container">
        <div class="editor-container editor-container_classic-editor" #editorContainerElement>
            <div class="editor-container__editor">
                <div #editorElement>
                    <ckeditor
                            [editor]="Editor"
                            [config]="config"
                            [formControl]="control"
                            *ngIf="isLayoutReady"
                            (change)="onChange($event)">
                    </ckeditor>
                </div>
            </div>
        </div>
    </div>
</div>