import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import {AdminPanelComponent} from "./admin-panel.component";
import {AdminPanelService} from "./service/admin-panel.service";
import {CardModule} from "../../components/card/card.module";
import {TableModule} from "../../components/table/table.module";
import {PaginationModule} from "../../components/pagination/pagination.module";
import {SecondaryButtonComponent} from "../../components/button/secondary/secondary.button.component";
import {PrimaryButtonComponent} from "../../components/button/primary/primary.button.component";
import {DiscountManageModule} from "../../components/discount-manage/discount-manage.module";
import {TextModule} from "../../components/text/text.module";


@NgModule({
    declarations: [AdminPanelComponent],
    imports: [
        CommonModule,
        AsyncPipe,
        CardModule,
        TableModule,
        PaginationModule,
        SecondaryButtonComponent,
        PrimaryButtonComponent,
        DiscountManageModule,
        TextModule
    ],
    providers: [AdminPanelService]
})
export class AdminPanelModule {
}
