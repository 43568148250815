<div class="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
    <input
            class="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-secondary-500 outline-none checked:border-primary checked:bg-primary hover:cursor-pointer focus:shadow-none"
            type="checkbox"
            [id]="id"
            [checked]="isChecked"
            [disabled]="isDisabled"
            (change)="onCheckboxChange($event)"
    />
    <label
            class="inline-block ps-[0.15rem] hover:cursor-pointer"
            [for]="id">
        {{ label }}
    </label>
</div>
