<div class="pt-24 md:pt-40 w-4/5 mx-auto">
    <div @slideDown>
        <app-text class="text-center text-4xl md:text-7xl font-black" [value]="'Наплата'"></app-text>
    </div>
    <div class="grid md:grid-cols-2 pt-3 md:mt-10">
        <!--        information's for delivery form-->
        <form [formGroup]="order">
            <hr class="h-px my-2 bg-gray-400 border-0">
            <app-text class="text-left md:text-3xl font-bold" [value]="'ИНФОРМАЦИИ ЗА ДОСТАВАТА'"></app-text>
            <div class="grid md:grid-cols-2 gap-5 pt-10">
                <div class="space-y-5">
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Име'"
                                        [control]="nameControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Презиме'"
                                        [control]="surnameControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Телефонски Број'"
                                        [control]="phoneNumberControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    @if (customer?.currentPoints!) {
                        <div>
                            <app-text [value]="'Поени: ' + customer?.currentPoints"></app-text>
                            <app-checkbox
                                    [label]="'Искористи поени'"
                                    (checkboxChange)="handleCheckboxChange($event)"
                            ></app-checkbox>
                        </div>
                    }
                </div>
                <div class="space-y-5">
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Адреса'"
                                        [control]="addressControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Град'"
                                        [control]="cityControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text-input [floatLabel]="true"
                                        [label]="'Е-маил адреса'"
                                        [control]="emailControl"
                                        [type]="'email'"></app-text-input>
                    </div>
                    @if (!customer?.currentPoints!) {
                        <div class="grid grid-cols-2 gap-3">
                            <app-text-input
                                    [floatLabel]="true"
                                    [label]="'Купон код'"
                                    [control]="couponCodeControl"></app-text-input>
                            @if (!isCouponCodeUsed) {
                                <app-secondary-button
                                        class="pt-2"
                                        [value]="'Искористи купон'"
                                        [isLoading]="isLoading"
                                        (click)="applyCoupon()"></app-secondary-button>
                            }
                        </div>
                    }
                </div>
            </div>
            <div class="py-5 md:pt-10">
                <app-primary-button
                        [value]="'КУПИ СЕГА'"
                        [isLoading]="isLoading"
                        (click)="openConfirmationModal()"></app-primary-button>
                <app-text class="pt-3 text-left text-gray-500"
                          [value]="'*Плаќање на врата при прием на пратката'"></app-text>
            </div>
        </form>

        <!--        Order items overview-->
        <div class="md:pl-20 pb-14">
            <div class="bg-gray-200 p-5 ">
                <div class="flex justify-between pb-2">
                    <app-text class="my-auto text-left lg:text-xl font-semibold"
                              [value]="'Преглед на нарачката:'"></app-text>
                    @if (orderItems.length > 0) {
                        <app-primary-button [class]="'lg:text-xl'" [value]="'измени нарачка'"
                                            (click)="goToUrl(router, '/cart')"></app-primary-button>
                    }
                </div>
                <hr class="h-px my-2 bg-gray-800 border-0">
                <app-text class="lg:text-xl font-medium"
                          [value]="orderItems.length + (orderItems.length === 1 ? ' Производ' : ' Производи')"></app-text>

                @for (orderItem of orderItems; track orderItem) {

                    <div class="flex justify-between pt-2">
                        <div class="flex">
                            <app-image [class]="'w-20 lg:w-24 max-w-24'"
                                       [src]="setOrderItemImage(orderItem)"></app-image>
                            <div class="pl-2 my-auto text-sm lg:text-base text-left max-w-40 lg:max-w-72">
                                <app-text
                                        [value]="orderItem?.quantity + ' X ' + setProductCategory(orderItem?.product?.category) + orderItem?.product?.name"></app-text>
                                <app-text class="uppercase" [value]="orderItem?.size"></app-text>
                            </div>
                        </div>
                        <app-text class="my-auto text-right max-w-10 lg:max-w-full"
                                  [value]="(orderItem?.product?.price ?? 0) * (orderItem?.quantity ?? 0) + ' ден.'"></app-text>
                    </div>
                }
                <hr class="h-px my-2 bg-gray-800 border-0">
                @if (orderItems.length > 0) {
                    @if (orderPrice < 1400) {
                        <app-text class="text-right text-gray-500 text-xs pb-5"
                                  [value]="'*гратис достава за нарачка над 1400 ден.'"></app-text>
                        <app-text class="text-right text-gray-500"
                                  [value]="'Достава: ' + deliveryExpenses + ' ден.'"></app-text>
                    } @else {
                        <app-text class="text-right text-gray-500 text-sm pb-5"
                                  [value]="'*достава гратис.'"></app-text>
                    }
                    @if (isCouponCodeUsed) {
                        <app-text class="text-right text-red-500"
                                  [value]="'Попуст: 10% &darr;'"></app-text>
                        <app-text class="text-right text-lg font-medium line-through"
                                  [value]="'ВКУПНО: ' + oldPrice + ' ден.'"></app-text>
                    }
                    @if (usePointsControl.value === true) {
                        <app-text class="text-right text-red-500"
                                  [value]="'Попуст: ' +  ((customer?.currentPoints! > totalPrice )?totalPrice: (customer?.currentPoints!)) + ' ден.   '"></app-text>

                    }
                    @if (usePointsControl.value === false) {
                        <app-text [ngClass]="isCouponCodeUsed? 'text-red-500' :''"
                                  class="text-right text-lg font-medium"
                                  [value]="'ВКУПНО: ' + totalPrice + ' ден.'"></app-text>
                    }
                    @if (usePointsControl.value === true) {
                        <app-text class="text-right text-lg font-medium line-through"
                                  [value]="'ВКУПНО: ' + totalPrice + ' ден.'"></app-text>
                        <app-text class="text-right text-lg font-medium text-red-500"
                                  [value]="'ВКУПНО: ' + ((customer?.currentPoints! > totalPrice )?0: (totalPrice-customer?.currentPoints!))  + ' ден.'"></app-text>
                    }
                }
            </div>
        </div>
    </div>
    <app-confirmation-modal (confirmationEvent)="submitOrder()"></app-confirmation-modal>
    <app-toast
            [message]="toastMessage"
    ></app-toast>
</div>