@if (auth.user$ | async; as user) {
    <div class="h-fit lg:p-16 pt-24 lg:pt-44">
        @if (customer?.couponCode) {
            <div @slideDown>
                <app-text class="text-center text-4xl lg:text-7xl font-black pb-12"
                          [value]="'Непокор бренд амбасадор'"></app-text>
            </div>
        }
        <div class="flex sm:flex-col md:flex-row pb-32 flex-wrap gap-10">
            <div class="mx-auto w-full lg:w-1/4 h-full px-5">
                <div class="border-2 border-black rounded-3xl p-2 mx-auto">
                    @if (user.email === environment.admin) {
                        <div class="pt-3 pb-3">
                            <app-primary-button [value]="'Админ панел'"
                                                (click)="goToUrl(router, '/admin-panel')"
                            />
                        </div>
                    }
                    <div class="flex pb-3 sm:flex-col flex-wrap md:flex-row">
                        <img class="w-32 rounded-lg shadow-lg mx-auto" src="{{user.picture}}" alt="profile pic">
                        <div class="flex md:pl-3 flex-col pt-3 mx-auto">
                            <app-text [value]="'Е-маил: ' + user.email"></app-text>
                            <app-secondary-button
                                    [value]="'Одјави се'"
                                    (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"/>
                        </div>
                    </div>
                    <div id="personalInfo">
                        <div>
                            <h2 class="mb-0" id="flush-headingOne">
                                <button
                                        class="group relative flex w-full items-center rounded-none bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                                        type="button"
                                        data-te-collapse-init
                                        data-te-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                    Лични податоци
                                </button>
                            </h2>
                            <div
                                    id="flush-collapseOne"
                                    class="!visible hidden"
                                    aria-labelledby="flush-headingOne"
                                    data-te-parent="#personalInfo">
                                <div class="px-5 py-4">
                                    <div>
                                        <app-text [value]="'Име'"></app-text>
                                        <app-text-input
                                                [placeholder]="'Внеси име'"
                                                [control]="nameControl"></app-text-input>

                                    </div>
                                    <div>
                                        <app-text [value]="'Презиме'"></app-text>
                                        <app-text-input
                                                [placeholder]="'Внеси презиме'"
                                                [control]="surnameControl"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Телефонски број'"></app-text>
                                        <app-text-input [placeholder]="'Внеси телефонски број'"
                                                        [control]="phoneNumberControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Адреса'"></app-text>
                                        <app-text-input [placeholder]="'Внеси адреса'"
                                                        [control]="addressControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Град'"></app-text>
                                        <app-text-input [placeholder]="'Внеси град на живеење'"
                                                        [control]="cityControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div class="pt-3 pb-3">
                                        <app-primary-button [value]="'Ажурирај податоци'"
                                                            (click)="updateCustomer()"></app-primary-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:w-full lg:w-1/2 h-full px-5 lg:px-0 lg:pr-10">
                <div class="w-full h-full lg:pr-32">
                    <div class="border-2 border-black rounded-3xl p-2 mx-auto h-full w-full">
                        <div class="flex flex-col pb-3 flex-wrap w-full pt-3">
                            @if (!customer?.couponCode) {
                                <div class="flex flex-wrap flex-row justify-end space-x-0 lg:space-x-3 space-y-3 lg:space-y-0">
                                    <app-text-input
                                            class="w-full lg:w-auto"
                                            [placeholder]="'Внеси купон код'"
                                            [control]="couponCodeControl"></app-text-input>
                                    <app-primary-button
                                            class="w-full lg:w-auto"
                                            [value]="customer?.couponCode? 'Промени код' :'Зачувај'"
                                            (click)="updateCouponCode()"></app-primary-button>

                                </div>
                            } @else {
                                <p class="p-5">Твојот купон код е: <strong class="text-red-500">{{ customer?.couponCode }}</strong></p>
                            }
                            <div id="couponCode">
                                <div class="rounded-none border border-e-0 border-s-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-body-dark">
                                    <h2 class="mb-0" id="flush-headingCoupon">
                                        <button
                                                class="group relative flex w-full items-center rounded-none bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                                                type="button"
                                                data-te-collapse-init
                                                data-te-target="#flush-couponCode"
                                                aria-expanded="false"
                                                aria-controls="flush-couponCode">
                                            {{ !customer?.couponCode ? 'Стани бренд амбасадор (кликни тука)' : 'Објаснување (кликни тука)' }}
                                        </button>
                                    </h2>
                                    <div
                                            id="flush-couponCode"
                                            class="!visible hidden"
                                            aria-labelledby="flush-headingCoupon"
                                            data-te-parent="#couponCode">
                                        <div class="px-2 py-4">
                                            <div class="space-y-3">
                                                <div class="max-w-lg mx-auto lg:p-6 border border-gray-300 rounded-lg bg-gray-50 px-1.5">
                                                    @if (!customer?.couponCode) {
                                                        <h2 class="text-center text-2xl font-bold text-gray-800">Стани
                                                            Непокор бренд амбасадор!</h2>
                                                    }
                                                    <p class="text-gray-600 mt-2">Сподели го твојот личен код и добиј
                                                        поени секогаш кога твој пријател
                                                        ќе
                                                        направи нарачка!</p>

                                                    <h3 class="text-lg font-semibold text-gray-800 mt-4">Како
                                                        функционира:</h3>
                                                    <ol class="list-decimal list-inside text-gray-600 mt-2">
                                                        @if (!customer?.couponCode) {
                                                            <li>Внеси твој уникатен купон код.</li>
                                                        }
                                                        <li>Сподели го кодот <span
                                                                class="font-bold text-red-500">{{ customer?.couponCode }} </span> –
                                                            твоите пријатели добиваат попуст од 10%
                                                            при нарачка.
                                                        </li>
                                                        <li>Заработи поени – добиваш 10% од вредноста на нарачките во
                                                            поени.
                                                        </li>
                                                    </ol>

                                                    <h3 class="text-lg font-semibold text-gray-800 mt-4">
                                                        Придобивки:</h3>
                                                    <ul class="list-disc list-inside text-gray-600 mt-2">
                                                        <li>Користи ги поените за да си купиш производи од сајтот (1
                                                            поен = 1 денар).
                                                        </li>
                                                        <li>Собери 500, 1000 или 1500 поени за бесплатни производи!</li>
                                                    </ul>
                                                    <p class="text-center text-xl font-bold text-gray-800 mt-6 mb-2">Почни да
                                                        заработуваш денес!</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-5">
                                <p>Вкупно поени: <strong>{{ customer?.totalPoints ?? 0 }}</strong></p>
                                <p class="py-3">Неискористени поени: <strong>{{ customer?.currentPoints ?? 0 }}</strong>
                                </p>
                            </div>

                            <!--      referral program prices-->
                            <div class="px-5">
                                <ol class="border-s-2 border-primary dark:border-primary-500">
                                    @for (product of referralProductPrices; track product) {
                                        <li>
                                            <div class="flex-start flex items-center">
                                                <div [ngClass]="(customer?.totalPoints! > checkpoints[$index]) ? 'opacity-50 bg-gray-200' : 'bg-primary dark:bg-primary-500'"
                                                     class="-ms-[9px] -mt-2 me-3 flex h-4 w-4 items-center justify-center rounded-full bg-primary dark:bg-primary-500"></div>
                                                <h4 [ngClass]="(customer?.totalPoints!>checkpoints[$index]) ? 'line-through text-gray-400' : ''"
                                                    class="-mt-2 lg:text-xl font-semibold">
                                                    Собери {{ checkpoints[$index] }}
                                                    поени и
                                                    добиј {{ product.name }}</h4>
                                            </div>
                                            <div class="mb-6 ms-6 pb-6">
                                                <div class="flex justify-center items-center lg:flex-col">

                                                    <app-image
                                                            [ngClass]="(customer?.totalPoints!>checkpoints[$index]) ? 'filter grayscale' : ''"
                                                            [class]="'h-44 lg:h-56 justify-center'"
                                                            [src]="'assets/images/' + product?.category?.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp'"></app-image>
                                                </div>
                                            </div>
                                        </li>

                                    }
                                    <div class="flex-start flex items-center">
                                        <div
                                                class="-ms-[9px] -mt-2 me-3 flex h-4 w-4 items-center justify-center rounded-full bg-primary dark:bg-primary-500"></div>
                                    </div>
                                </ol>
                            </div>
                            <!--      referral program prices end here-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="relative overflow-hidden px-5 space-y-6  pt-28 lg:pt-44 md:w-1/2 h-fit mx-auto">
<!--        <app-primary-button-->
<!--                class="w-8 lg:w-9 button-on-hover z-[100]"-->
<!--                [value]="'Логирај се'"-->
<!--                [tooltip]="'Profile'"-->
<!--                (click)="auth.loginWithRedirect()"/>-->
        <div class="py-4">
            <div class="space-y-3">
                <div class="max-w-lg mx-auto p-6 border border-gray-300 rounded-lg bg-gray-50">
                    <h2 class="text-center text-2xl font-bold text-gray-800">Стани Непокор бренд амбасадор!</h2>
                    <p class="text-gray-600 mt-2">Сподели го твојот личен код и добиј поени секогаш кога твој пријател
                        ќе
                        направи нарачка!</p>

                    <h3 class="text-lg font-semibold text-gray-800 mt-4">Како функционира:</h3>
                    <ol class="list-decimal list-inside text-gray-600 mt-2">
                        <li>Логирај се преку Google или Facebook.</li>
                        <app-primary-button
                                class="w-8 lg:w-9 button-on-hover z-[100]"
                                [value]="'Логирај се'"
                                [tooltip]="'Profile'"
                                (click)="auth.loginWithRedirect()"/>
                        <li>Внеси твој уникатен купон код.</li>
                        <li>Сподели го кодот – твоите пријатели добиваат попуст од 10% при нарачка.</li>
                        <li>Заработи поени – добиваш 10% од вредноста на нарачките во поени.</li>
                    </ol>

                    <h3 class="text-lg font-semibold text-gray-800 mt-4">Придобивки:</h3>
                    <ul class="list-disc list-inside text-gray-600 mt-2">
                        <li>Користи ги поените за да си купиш производи од сајтот (1 поен = 1 денар).</li>
                        <li>Собери 500, 1000 или 1500 поени за бесплатни производи!</li>
                    </ul>
                    <p class="text-center text-xl font-bold text-gray-800 mt-6">Почни да заработуваш денес!</p>
                </div>

            </div>

        </div>
    </div>
}
<app-toast [message]="toastMessage"></app-toast>
<app-footer></app-footer>