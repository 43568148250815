import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderModule} from "./components/header/header.module";
import {AboutUsModule} from "./modules/about-us/about-us.module";
import {ShopModule} from "./modules/shop/shop.module";
import {CartModule} from "./modules/cart/cart.module";
import {CheckoutModule} from "./modules/checkout/checkout.module";
import {HomeModule} from "./modules/home/home.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ButtonModule} from "./components/button/button.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OrderDialogModule} from "./modules/shop/components/order-dialog/order-dialog.module";

import {LoginDialogComponent} from "./components/login/login-dialog/login-dialog.component";
import {ProfileModule} from "./modules/profile/profile.module";
import {AuthModule} from "@auth0/auth0-angular";
import {environment} from "./environments/environment";
import {ContactUsModule} from "./modules/contact-us/contact-us.module";
import {CartOverviewModule} from "./components/cart-overview/cart-overview.module";
import {AdminPanelModule} from "./modules/admin-panel/admin-panel.module";
import {StatusPageModule} from "./components/status-page/status-page.module";
import {ProductManageModule} from "./modules/shop/components/product-manage/product-manage.module";
import {EmailMarketingModule} from "./modules/admin-panel/components/email-marketing/email-marketing.module";
import {BlogModule} from "./modules/blog/components/blog.module";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {BrandAmbassadorModule} from "./modules/brand-ambassador/brand-ambassador.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HeaderModule,
        AboutUsModule,
        ShopModule,
        CartModule,
        CheckoutModule,
        HomeModule,
        ButtonModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        ProfileModule,
        ContactUsModule,
        OrderDialogModule,
        AdminPanelModule,
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin
            }
        }),
        LoginDialogComponent,
        CartOverviewModule,
        StatusPageModule,
        ProductManageModule,
        EmailMarketingModule,
        BlogModule,
        CKEditorModule,
        BrandAmbassadorModule
    ],
    exports: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
