import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {BlogDetailComponent} from "./blog-detail/blog-detail.component";
import {ImageModule} from "../../../components/image/image.module";
import {LinkModule} from "../../../components/link/link.module";
import {TextModule} from "../../../components/text/text.module";
import {BlogManageComponent} from "./blog-manage/blog-manage.component";
import {BlogService} from "./service/blog.service";
import {ConfirmationModalModule} from "../../../components/confirmation-modal/confirmation-modal.module";
import {DropdownMenuModule} from "../../../components/dropdown/dropdown-menu.module";
import {FormsModule} from "@angular/forms";
import {PrimaryButtonComponent} from "../../../components/button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../../../components/button/secondary/secondary.button.component";
import {TextInputModule} from "../../../components/text-input/text-input.module";
import {RichTextComponent} from "../../../components/rich-text/rich-text.component";
import {OrderDialogModule} from "../../shop/components/order-dialog/order-dialog.module";
import {FooterModule} from "../../../components/footer/footer.module";
import localeMk from '@angular/common/locales/mk';
import {BlogListComponent} from "./blog-list/blog-list.component";
import {RelatedProductsModule} from "../../shop/components/related-products/related-products.module";

registerLocaleData(localeMk);

@NgModule({
    declarations: [BlogDetailComponent, BlogManageComponent, BlogListComponent],
    exports: [
        BlogDetailComponent, BlogManageComponent, BlogListComponent
    ],
    imports: [
        CommonModule,
        ImageModule,
        LinkModule,
        TextModule,
        ConfirmationModalModule,
        DropdownMenuModule,
        FormsModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        TextInputModule,
        RichTextComponent,
        OrderDialogModule,
        FooterModule,
        RelatedProductsModule
    ],
    providers: [BlogService, DatePipe, {provide: LOCALE_ID, useValue: 'mk'}]

})
export class BlogModule {
}