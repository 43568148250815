import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrl: './checkbox.component.css'
})
export class CheckboxComponent {

    @Input() label: string = '';

    @Input() id: string = '';

    @Input() isChecked: boolean = false;

    @Input() isDisabled: boolean = false;

    @Output() checkboxChange = new EventEmitter<boolean>();

    onCheckboxChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.checkboxChange.emit(target.checked);
    }
}
