import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ColumnMode} from "@swimlane/ngx-datatable";

export interface RowData {
    id: number;
    name: string;
}

export interface ActivateEvent {
    type: string;
    event: PointerEvent;
    row: RowData;
    value: string;
}

export interface TableColumn {
    prop: string;
    name: string;
    width?: number;
    sortable?: boolean;
    filterable?: boolean;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.css'
})
export class TableComponent<T> {

    protected readonly ColumnMode = ColumnMode;

    @Input() rows: T[] = [];

    @Input() columns: TableColumn[] = [];

    @Output() rowClicked = new EventEmitter<ActivateEvent>();

    onActivate(event: ActivateEvent): void {
        if (event.type === 'click') {
            this.rowClicked.emit(event);
        }
    }

}